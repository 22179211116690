/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

li:hover div {
  display: block;
}

.nav li div ul > ul > li > as:after {
  content: ""; /* Required to display content */
  position: absolute; /* Sets the position absolute to the top div */
  top: 0;
  left: 7.5%; /* position the little arrow */
  margin-left: -15px;
  margin-top: -15px; /* Set margin equal to border px */
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid 15px #ddd; /* Creates the arrow pointing up, to change to a notch instead user border-top */
  border-left: solid 15px transparent; /* Creates triangle effect */
  border-right: solid 15px transparent; /* Creates triangle effect */
}

#ratingStar {
  display: flex !important;
  gap: 3px !important;
  margin-top: 10px !important;
}

/* ---------- blog post styles ----------- */

.blog-container {
  margin: 2em auto;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  max-width: 800px;
  background-color: #fff;
}

.blog-header {
  background-color: #f7f7f7;
  padding: 1.5em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.blog-title {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 0.5em;
  line-height: 1.2;
}

@media screen and (max-width: 640px) {
  .blog-container {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .blog-title {
    font-size: 2rem;
    padding: 10px;
  }

  .blog-post {
    font-size: 14px;
    padding: 10px !important;
  }
}

/* ----- mdx styles ----- */

.blog-post {
  max-width: 700px;
  margin: 0 auto;
  padding: 32px;
  font-family: "Roboto";
  line-height: 1.3; /* added light height for all tags */
}

.blog-post h1 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 32px;
  font-family: "Roboto";
}

.blog-post h2 {
  font-size: 32px;
  font-weight: 500;
  margin-top: 48px;
  margin-bottom: 24px;
  font-family: "Roboto";
}

.blog-post p {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: "Roboto";
}

.blog-post ul,
.blog-post ol {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 24px;
  font-family: "Roboto";
}

.blog-post ul li,
.blog-post ol li {
  margin-bottom: 8px;
  font-family: "Roboto";
}

.blog-post img {
  max-width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.blog-post blockquote {
  font-size: 24px;
  font-style: italic;
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 24px;
  background-color: #f8f8f8;
  border-left: 4px solid #ccc;
}

.blog-post pre {
  font-size: 18px;
  font-family: "Roboto";
  background-color: #f8f8f8;
  padding: 0.9em 0.7em;
  border-radius: 3px;
}

/* .blog-post a {
  position: relative;
  color: #0abab5;
  text-decoration: underline wavy #0abab5;
  -webkit-text-decoration: underline wavy #0abab5;
  text-decoration: underline wavy #0abab5;
  font-family: "Roboto";

} */

.blog-post a {
  font-weight: 400;
  color: #0abab5;
  padding-bottom: 5px;
  background: url("./assets/svg/wavy.svg");
  background-repeat: repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto auto;
  background-repeat: repeat-x;
  background-size: 15px 5px;
  background-position: 2px 19px;
  animation: move 15s linear infinite;
  -webkit-animation: move 15s linear infinite;
  animation-play-state: paused;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.blog-post a:hover {
  animation-play-state: running;
}

@keyframes move {
  0% {
    background-position: 2px 19px;
    transform: translateX(0);
  }
  20% {
    transform: translateX(2px);
  }
  40% {
    transform: translateX(-2px);
  }
  60% {
    transform: translateX(2px);
  }
  80% {
    transform: translateX(-2px);
  }
  100% {
    background-position: 500px 19px;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .blog-post h1 {
    font-size: 36px;
    margin-bottom: 16px;
  }

  .blog-post h2 {
    font-size: 28px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .blog-post p,
  .blog-post ul,
  .blog-post ol {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 16px;
    padding-left: 16px;
  }

  .blog-post blockquote {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 16px;
  }

  .blog-post pre {
    font-size: 16px;
    padding: 0.1em 0.2em;
  }
}

@media (max-width: 576px) {
  .blog-post h1 {
    font-size: 28px;
    margin-bottom: 12px;
  }

  .blog-post h2 {
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .blog-post p,
  .blog-post ul,
  .blog-post ol {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 16px;
  }

  .blog-post blockquote {
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 16px;
  }

  .blog-post img {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .blog-post pre {
    font-size: 16px;
    padding: 0.15em 0.3em;
  }
}
